import './App.css';
import {useCallback, useEffect, useState} from "react";

const STORAGE_KEY = "activities"
const PROBABILITY_KEY = "probability"

const DEFAULT_PROBABILITY = 60

function App() {

  const [activities, setActivities] = useState([]);
  const [selected, setSelected] = useState({})
  const [probability, setProbability] = useState(DEFAULT_PROBABILITY)
  const [activityInputs, setActivityInputs] = useState(1);

  useEffect(() => {
    const rawActivities = localStorage.getItem(STORAGE_KEY)
    const probability = localStorage.getItem(PROBABILITY_KEY)

    setProbability(probability ?? DEFAULT_PROBABILITY)

    try {
      const activities = JSON.parse(rawActivities)
      setActivities(activities ?? [])
    } catch (e) {
    }
  }, [])

  const handleProbability = useCallback((e) => {
    setProbability(e.target.value)
    localStorage.setItem(PROBABILITY_KEY, e.target.value)
  }, [])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()

    const activity = e.target.elements.activity.value;

    if (activities.includes(activity)) {
      return;
    }

    setActivities((activities) => [...activities, activity])
  }, [activities])

  const randomize = useCallback(() => {
    const selected = activities.reduce((acc, activity) => {
      const r = Math.random();

      if (r > 1 - probability / 100) {
        acc[activity] = true;
      }

      return acc;
    }, {})

    setSelected(selected);
  }, [activities, probability])

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(activities))
  }, [activities])

  const removeActivity = useCallback((activity) => {
    const filtered = activities.filter(item => item !== activity)
    setActivities(filtered)
  }, [activities])

  return (
    <div className="App grid md:grid-cols-[repeat(2,minmax(360px,1fr))] md:p-12 gap-12">
      <div className={"prose p-4"}>
        <h1>enjoy ☺️ - your training helper</h1>
        <article className={"text-left"}>
          <h2>How it works?</h2>
          <p>Do you stack things you enjoy with training?</p>
          <ul className={"w-max text-left"}>
            <li>you train ☺️</li>
            <li>with music on ☺️</li>
            <li>and maybe energy drink before ☺️</li>
            <li>do you enjoy such workout? ☺️☺️☺️</li>
          </ul>
          <p>It is be great at the beginning. As time goes on, it gets harder to work out. Your motivation drops.</p>
          <ul>
            <li>What if
              it's because of things you enjoy?
            </li>
            <li>And because of how body works.</li>
            <li>How so?</li>
          </ul>
          <p>Our body, uses molecules called dopamine. It drives motivation. But it gets used to recurring level of
            pleasure. If we train with music or energy drink, such 'stack' becomes a habit. Less pleasurable habit. It
            wants more. What to do, then?</p>
          <ul>
            <li>raise the stake, bring more pleasure</li>
            <li>or...</li>
            <li>randomize your stack</li>
          </ul>
          <p>If stacking doesn't get periodic body won't get used to the level. Like with social media scrolling, you
            don't know
            what happens next. You could roll the dice, or you can use the randomizer here. This will help your body to
            not get used to the same stack and to not expect certain pleasures. </p>
          <p>It's beta version, so certain features might change</p>
        </article>
      </div>
      <div className={`prose p-2 md:p-8 m-1 mb-4 pb-4 flex flex-col text-left 
      rounded-xl border-2 border-dashed border-indigo-500`}>
        <div>
          <h2>Your training activities:</h2>
          <div className={"not-prose"}>
            <ul className={'mt-12 ml-4 w-max max-w-full not-prose space-y-2'}>
              {activities.map((activity, index) =>
                <li key={index}
                    className={"flex justify-between relative"}>{
                  selected[activity] &&
                  <input
                    className={`absolute right-full rounded-full 
                    form-input text-green-500 self-center 
                    font-bold block p-2 md:p-4 drop-shadow-md`}
                    type="checkbox" checked readOnly
                  />}
                  <input readOnly
                         value={activity}
                         className={"mx-2 px-2 rounded bg-neutral-100"}
                  />
                  <button className={"bg-red-100 px-4 py-1 rounded drop-shadow-md"}
                          onClick={() => removeActivity(activity)}>
                    ❌
                  </button>
                </li>)}
              {!activities.length && <>
                <li>No activities.</li>
                <li>Add your activites from the input below</li>
              </>}
            </ul>
          </div>
        </div>
        <div className={"space-y-6 flex flex-col my-6"}>
          <details className={"open:border-b-2 border-dashed border-indigo-500 pt-4 open:pb-4"}>
            <summary className={"text-indigo-500 underline cursor-pointer mb-6"}>👉 new activity</summary>
          <form onSubmit={handleSubmit}>
            <label className={"mr-4"}>
              <input
                type="text"
                placeholder={"Write new activity here"}
                className={"form-input bg-neutral-50 rounded"}
                name={"activity"}
              />
            </label>
            <div className={"flex items-center mt-4 w-max gap-4"}>
            <button type={"submit"}
                    className={`bg-indigo-500 
                  text-white px-8 py-1 rounded 
                  drop-shadow-md font-medium 
                  disabled:cursor-not-allowed`}>
              🌱 Add
            </button>
            {/*  or*/}
            {/*<button className={"text-indigo-500 underline my-2"}>add alternative</button>*/}
            </div>
          </form>
          </details>
          <div className={"flex flex-col space-y-2"}>
            <label className={"mr-4"}>
              Probability for each activity: {probability}%
            </label>
            <input
              type="range"
              className={"form-input rounded max-w-[260px]"}
              name={"probability"}
              min={0}
              max={100}
              onChange={handleProbability}
              value={probability}
            />
          </div>
          <button
            className={`rounded-full drop-shadow-lg 
          ${activities.length ? "bg-indigo-500" : "bg-neutral-400"} 
          text-white w-full max-w-[380px] py-4 font-medium mr-auto`}
            onClick={randomize} disabled={!activities.length}>
            👉 Pick my stack
          </button>
        </div>
        <h3>How to use?</h3>
        <ol>
          <li>Add workout activities you stack together</li>
          <li>Click "Pick my stack"</li>
          <li>Activities will be randomly picked</li>
          <li>Do your next workout with with drawn activities</li>
          <li>enjoy ☺️</li>
        </ol>
        <span>PS: activities stay here between restarts</span>
        <span>PPS: Inspiration for this app thanks to <a
          href={"https://hubermanlab.com/controlling-your-dopamine-for-motivation-focus-and-satisfaction/"}>episode #39 of Huberman Lab podcast</a>
        </span>
      </div>
    </div>
  );
}

export default App;
